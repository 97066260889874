import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"

const IndexPage = () => (
  <Layout lang="pt">
    <Seo title="Produtividade e Organização de Tempo" description="App para organizar, planejar e gerenciar seu tempo. Ele ajuda a controlar o seu dia e visualizar em quais momentos você está desperdiçando tempo. Pare de procrastinar, seja mais produtivo e alcance seus objetivos!" lang="pt" />
    <Hero locale="pt" />
  </Layout>
)

export default IndexPage
