import React from "react";
import styled from "styled-components";
import GooglePlay from '../assets/google-play.png'
import AppStore from '../assets/app-store.png'
import HeroImage from '../assets/hero-image.png'
import {InlineButtons} from "./styles/global-styles";

const heroStrings = {
  header: {
    "en": 'Discover why you never have enough time.',
    "pt": 'Descubra porquê nunca sobra tempo no seu dia.',
  },
  subHeader: {
    "en": "This is the ultimate time tracking app. It will help you track your day and visualize where you're wasting time. Stop procrastinating, be more productive and reach your goals!",
    "pt": "Este é o app que você precisa para organizar, planejar e gerenciar seu tempo de uma forma completamente nova. Ele ajuda a controlar o seu dia e visualizar em quais momentos você está desperdiçando tempo. Pare de procrastinar, seja mais produtivo e alcance seus objetivos!",
  },
}

const Hero = ({locale}) => {
  const lang = locale || "en";
  return <HeroContainer>
    <HeroContent>
      <HeroItems>
        <img src={HeroImage} alt="Main illustration" width="80%" />
        <HeroH1 colored="#B393FF">{heroStrings.header[lang]}</HeroH1>
        <HeroP>{heroStrings.subHeader[lang]}</HeroP>
        <InlineButtons>
          <a href="https://apps.apple.com/us/app/id1519636469" rel="noopener" ><DownloadAppButton src={AppStore} alt="Baixar App na App Store" /></a>
          <a href="https://play.google.com/store/apps/details?id=com.soneto.blocos" rel="noopener" ><DownloadAppButton src={GooglePlay} alt="Baixar App na Google Play" /></a>
        </InlineButtons>
      </HeroItems>
    </HeroContent>
  </HeroContainer>
}

export default Hero

const HeroContainer = styled.div`
  background: #253066;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 1rem;
  color: #fff;
  min-height: 100vh;
`

const HeroContent = styled.div`
  max-width: 80vw;
  padding: 5rem calc((100vw - 1300px) / 2);
`

const HeroItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  line-height: 1.2;

  img {
    max-width: 700px;
  }
`

const HeroH1 = styled.h1`
  font-size: clamp(1.5rem, 6vw, 2.5rem);
  margin-bottom: 1.2rem;
  margin-top: 2rem;
  font-weight: 700;
  padding: 0 1rem;
  color: #EBAC94;

  span {
    color: ${({colored}) => (colored ? colored : 'white')};
    font-weight: bold;
  }
`
const HeroP = styled.p`
  font-size: clamp(1rem, 3vw, 1.1rem);
  color: #EFEFEF;
  font-weight: 500;
  line-height: 1.3;
`

const DownloadAppButton = styled.img`
  object-fit: contain;

  @media screen and (min-width: 768px) {
    height: 70px;
    margin: 0 0.5rem 0 0.5rem;
  }
  @media screen and (max-width: 768px) {
    width: 180px;
    margin-bottom: 0.5rem;
  }
`